import { CheckCircle, XCircle } from "@phosphor-icons/react";
import { toast } from "sonner";

interface ToastProps {
  icon: React.ReactNode;
  message: string;
}

const CustomToast = ({ icon, message }: ToastProps) => (
  <div className="min-w-[400px] flex justify-center">
    <div
      className="bg-white backdrop-filter backdrop-blur-[10px] rounded-3xl p-4 flex justify-center items-center gap-2 w-fit"
      style={{
        boxShadow:
          "0px 0px var(--32, 32px) 0px var(--color-shadow-neutral-light, rgba(0, 0, 0, 0.06)), 0px var(--4, 4px) var(--20, 20px) -8px var(--color-shadow-neutral-medium, rgba(0, 0, 0, 0.11))",
      }}
    >
      <div>{icon}</div>
      <div className="font-semibold text-neutural-strong">{message}</div>
    </div>
  </div>
);

const toastSuccess = (message: string) => {
  return toast.custom(() => (
    <CustomToast
      icon={
        <CheckCircle weight="fill" className="text-primary-content size-6" />
      }
      message={message}
    />
  ));
};

const toastError = (message: string) => {
  return toast.custom(() => (
    <CustomToast
      icon={<XCircle weight="fill" className="text-vintageRed-600 size-6" />}
      message={message}
    />
  ));
};

export { toastSuccess, toastError };
