/* eslint-disable @next/next/no-img-element */
"use client";

import { toastSuccess } from "@/components/ToastComponent";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { CHECK_DEPOSIT, GET_DEPOSIT_CODE } from "@/lib/graphql";
import { formatCurrency } from "@/lib/helper";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { ClockCountdown, Spinner } from "@phosphor-icons/react";
import Image from "next/image";
import React from "react";
import Countdown from "react-countdown";

type Props = {
  open: boolean;
  onClose: () => void;
};

const DepositModal = ({ open, onClose }: Props) => {
  const queryClient = useApolloClient();
  const EXPIRED_TIME = React.useMemo(() => {
    if (open) {
      return Date.now() + 1000 * 60 * 5;
    }
    return null;
  }, [open]);

  const [getDepositCode, { loading: loadingDepositCode, data: depositCode }] =
    useLazyQuery(GET_DEPOSIT_CODE, {
      fetchPolicy: "network-only",
    });

  const [checkDeposit, { data: depositResult, reset }] = useMutation(
    CHECK_DEPOSIT,
    {
      onCompleted: (data) => {
        if (data?.response.data.transaction.status === "SUCCESS") {
          toastSuccess(
            `Đã nạp ${formatCurrency(
              data?.response.data.transaction.amount
            )} vào tài khoản của bạn!`
          );
          queryClient.refetchQueries({ include: ["getMe"] });
          onClose();
        }
      },
      onError: (error) => {},
    }
  );

  React.useEffect(() => {
    if (open) {
      getDepositCode();
    }
  }, [open, getDepositCode]);

  React.useEffect(() => {
    if (!depositCode || !open) return;
    const interval = setInterval(() => {
      checkDeposit({
        variables: {
          params: {
            depositCode: depositCode?.response.data.depositCode,
            provider: "ACB",
          },
        },
      });
    }, 5000);
    if (depositResult) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [depositCode, depositResult, open, checkDeposit]);

  const items = React.useMemo(
    () => [
      {
        title: "Số Tài Khoản",
        value: "4235887",
      },
      {
        title: "Tên Tài Khoản",
        value: "NGUYEN HUY LAN",
      },
      {
        title: "Ngân Hàng",
        value: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="24"
            viewBox="0 0 58 24"
            fill="none"
          >
            <g clipPath="url(#clip0_521_27675)">
              <path
                d="M57.251 14.701C56.954 13.1958 56.0099 11.9369 54.6603 11.2527C54.2557 11.0337 53.7972 10.8696 53.4732 10.7054C53.9857 10.2948 54.6337 9.93908 55.0653 9.4191C56.1444 8.16021 56.4954 6.70976 56.1714 5.06773C55.9284 3.58991 55.0113 2.33102 53.7162 1.64684C52.367 0.880559 50.8829 0.634255 49.3717 0.60689C46.8894 0.579522 44.3802 0.60689 41.8976 0.60689C41.2771 0.60689 41.1421 0.743725 41.1421 1.37317V21.2965C41.1421 21.8712 41.304 22.0626 41.8707 22.0626C42.95 22.0626 44.0292 22.09 45.1087 22.0626C47.2674 22.008 49.3987 21.9806 51.5574 21.8712C52.7446 21.8164 53.9052 21.4333 54.9033 20.8038C55.8209 20.2564 56.5494 19.4081 56.927 18.3956C57.4126 17.2461 57.5206 15.9325 57.251 14.701ZM45.8369 4.49301C47.2404 4.62986 48.6162 4.27407 49.9653 4.71197C50.9909 5.04038 51.5304 5.83401 51.4494 6.9834C51.3685 7.88653 50.9639 8.78966 49.7767 8.95384C48.5086 9.14541 47.186 9.14541 45.8369 9.25491V4.49301ZM52.394 15.9599C52.286 17.0545 51.4494 17.9303 50.3703 18.0397C48.8862 18.2313 47.375 18.2313 45.8369 18.3408V12.7579C47.456 12.8673 49.0482 12.5389 50.6129 13.0589C51.7464 13.4421 52.529 14.2083 52.394 15.9599Z"
                fill="#0038A8"
              />
              <path
                d="M31.6981 0.196289C34.2615 0.27839 36.609 1.04467 38.5787 2.76879C38.7405 2.90563 38.8755 3.0151 39.0374 3.15193C39.4692 3.50771 39.5231 3.83612 39.0913 4.30136C38.4438 5.04026 37.7692 5.72443 37.0676 6.38126C36.9327 6.5181 36.7708 6.60021 36.5819 6.60021C36.3931 6.60021 36.2042 6.5181 36.0963 6.38126C35.2058 5.58759 34.1265 5.06764 32.9663 4.84869C31.4283 4.60239 29.8363 4.98553 28.5681 5.916C26.7602 7.22961 25.7888 9.14533 25.7349 11.5263C25.7618 14.564 27.4888 17.0817 30.16 17.9849C32.4265 18.7512 34.4233 18.1764 36.2042 16.5892C36.5819 16.2608 36.8248 16.2334 37.1485 16.5892C37.8501 17.3007 38.5247 18.0123 39.2262 18.7238C39.5771 19.1069 39.5771 19.4627 39.1992 19.8185C36.3392 22.6099 32.2647 23.5952 28.5141 22.4457C27.03 21.9804 25.6539 21.1869 24.4936 20.1196C22.5779 18.4228 21.3097 16.0966 20.932 13.5514C20.4193 10.1853 21.0939 7.06543 23.2254 4.41081C24.8445 2.3583 26.949 0.962567 29.5395 0.497327C30.214 0.305757 30.9425 0.196289 31.6981 0.196289Z"
                fill="#0038A8"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.203 11.6085C36.176 13.0042 35.4205 14.2904 34.2063 14.9747C32.9921 15.6589 31.5349 15.6315 30.3478 14.92C29.1606 14.2084 28.459 12.8948 28.459 11.4991C28.459 10.4591 28.8637 9.47388 29.5922 8.73498C30.3208 7.99608 31.2921 7.61294 32.3175 7.64028C34.4492 7.64028 36.2301 9.22758 36.203 11.6085Z"
                fill="#00AFFF"
              />
              <path
                d="M11.0826 5.91632C10.2191 8.57094 9.38269 11.1435 8.51925 13.7433H13.565L11.0826 5.91632ZM14.1857 1.67443C15.4538 5.23215 16.7491 8.7899 18.0441 12.3476C19.1775 15.4675 20.3107 18.56 21.444 21.6524C21.6868 22.3366 21.5788 22.4735 20.8504 22.4735H17.0998C16.6411 22.5008 16.2364 22.1998 16.1284 21.762C15.6966 20.4483 15.238 19.1621 14.8332 17.8484C14.8332 17.7389 14.7793 17.6568 14.6982 17.6021C14.6173 17.5474 14.5094 17.52 14.4284 17.52H7.68277C7.41294 17.52 7.33198 17.6294 7.25107 17.8484C6.81932 19.1621 6.36062 20.4757 5.95588 21.8166C5.84792 22.2272 5.47018 22.5008 5.06544 22.4461C3.82423 22.4461 2.58301 22.4461 1.34181 22.4461C0.802154 22.4461 0.66724 22.2545 0.85612 21.7346C1.80052 19.2441 2.71794 16.7811 3.63535 14.2906C4.39087 12.2929 5.11939 10.2951 5.84792 8.2973C6.65741 6.13528 7.43994 3.94591 8.24942 1.7839C8.49225 1.18183 8.76208 1.01763 9.40965 1.01763H13.2143C13.673 0.99026 14.0507 1.26393 14.1857 1.67443Z"
                fill="#0038A8"
              />
            </g>
            <defs>
              <clipPath id="clip0_521_27675">
                <rect width="57.8571" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
      },
      {
        title: "Nội dung chuyển tiền",
        value: depositCode?.response?.data?.depositCode,
      },
    ],
    [depositCode]
  );

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog modal open={open} onOpenChange={handleClose}>
      <DialogContent
        className="md:w-[600px] w-[96vw] max-h-[96vh] md:h-fit h-full overflow-auto"
        title="Nạp Tiền"
      >
        <div className="mx-3 p-4 bg-info-default text-white font-semibold rounded-3xl flex md:gap-1 gap-4 md:items-center items-start mt-4">
          <ClockCountdown className="text-lg" weight="fill" />
          <div className="flex gap-2 md:items-center md:flex-row flex-col items-start">
            Phiên giao dịch sẽ kết thúc sau:
            <Countdown
              date={EXPIRED_TIME as number}
              className="text-white text-xl"
            />
          </div>
        </div>
        <div className="grid md:grid-cols-8 grid-cols-1 p-3 gap-3 h-full">
          <div className="md:col-span-5 h-full">
            <div className="p-4 bg-white rounded-3xl h-full">
              <div className="h-full">
                {loadingDepositCode ? (
                  <div className="flex items-center justify-center h-full">
                    <Spinner
                      className="animate-spin text-neutural-strong text-center"
                      size={24}
                    />
                  </div>
                ) : (
                  <Image
                    height={300}
                    width={300}
                    src={depositCode?.response?.data?.qrCode.ACB!}
                    alt="qr-code"
                    className="object-fit h-full w-full rounded-lg"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="md:col-span-3 h-full">
            <div className="p-4 bg-white rounded-3xl h-full">
              <div className="grid md:grid-cols-1">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={`${index !== items.length - 1 ? "mb-6" : ""}`}
                  >
                    <div className="text-lg text-neutural-strong font-semibold">
                      {item.value}
                    </div>
                    <div className="text-sm text-neutural-medium">
                      {item.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="md:text-lg text-md font-semibold w-full text-center mb-3 md:mt-0 mt-10">
          Tiền sẽ được nạp vào ví của bạn sau 1 - 2 phút
        </div>
        <div className="h-16 md:hidden visible" />
      </DialogContent>
    </Dialog>
  );
};

export default DepositModal;
